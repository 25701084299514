import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "accompagnement",
    path: "accueil / nos métiers / accompagnement / chef de projet"
  },
  title: "Chef de projet", 
	missions:[
		{
		  text: "Le chef de projet analyse les besoins, organise, planifie et met en œuvre des services ou produits pour des clients internes ou externes" 
		},
	],
	key_skills: [
		{
		  text: "Connaître et comprendre le vocabulaire technique utilisé dans les métiers" 
		},
		{
		  text: "Maîtrise de la conduite de projets informatiques en environnement NTIC : techniques de recueil, analyse, qualification des besoins, élaboration des scénarios, de maquettes, rédaction de cahier des charges et chiffrage du projet, dépouillement d’appels d’offre…" 
		},
		{
		  text: "Maîtrise des outils de suivi de projet" 
		},
		{
		  text: "Gestion et contrôle des budgets et des coûts informatiques" 
		},
	],
	prerequisites:[
		{
		  text: "Avoir un certain talent pour la communication" 
		},
		{
		  text: "Être force de proposition" 
		},
		{
		  text: "Avoir des qualités relationnelles pour pouvoir discuter facilement avec ses collaborateurs et avec les clients" 
		},
		{
		  text: "Être organisé pour pouvoir respecter les délais prévus dans le calendrier" 
		},
		{
		  text: "Avoir des qualités managériales pour pouvoir gérer les équipes qui travailleront pour vous" 
		},
		{
		  text: "" 
		},
		{
		  text: "" 
		},
		{
		  text: "Management" 
		},		
	],
	activities:[
		{
		  text: "Analyse des besoins" 
		},
		{
		  text: "Recenser et analyser les besoins" 
		},
		{
		  text: "Accompagner les utilisateurs dans leur réflexion sur les usages" 
		},
		{
		  text: "Participer à la priorisation des projets et à la cohérence des demandes" 
		},
		{
		  text: "Rédiger les cahiers des charges en accord avec les donneurs d’ordre" 
		},
		{
		  text: "Rédiger les réponses aux appels d'offres" 
		},
		{
		  text: "Argumenter les préconisations" 
		},
		{
		  text: "Définir une enveloppe budgétaire" 
		},
		{
		  text: "Rédiger l’analyse fonctionnelle détaillée" 
		},
		{
		  text: "Gestion du projet" 
		},
		{
		  text: "Définir l’arborescence des projets" 
		},
		{
		  text: "Etablir le planning, définir les ressources, le découpage du projet ainsi que le budget détaillé" 
		},
		{
		  text: "Gérer les priorités du projet" 
		},
		{
		  text: "Coordonner le travail des différentes équipes : architectes, CP techniques et développeurs (MOE)…" 
		},
		{
		  text: "Suivre l’avancement des éventuels prestataires externes" 
		},
		{
		  text: "Gérer la recette fonctionnelle" 
		},
		{
		  text: "Coordonner le déploiement du projet" 
		},
	]
}
const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;
